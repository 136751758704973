<template>
    <div>
        <v-row>
            <v-col lg=12>
<s-crud title="Lotes de Recepción" edit search-input  :config=config :filter=filter @save=save($event) excel>
    <template v-slot:filter='props'>
        <v-col>
            <v-row justify="center">
                <v-col lg=3 cols=12><s-select-definition clearable v-model="filter.TypeDestiny" label="Destino" :def=1181 /></v-col>
                <v-col lg=3 cols=12><s-date label="Inicio" v-model=filter.LorBeginDate /></v-col>
                <v-col lg=3 cols=12><s-date label="Fin" v-model=filter.LorEndDate /></v-col>
            </v-row>
        </v-col>
       
    </template>
    <template v-slot:LorStatus='{row}'>
      <v-chip :color="row.TrzStatus==2?'default':'success'" x-small>{{row.TrzStatus==2?'Pendiente':'Creado'}}</v-chip>
    </template>
    <template v-slot:TypeDestiny='{row}'>
      <v-chip :color="row.TypeDestiny==2?'primary':row.TypeDestiny==1?'success':row.TypeDestiny==3?'purple':'default'" 
      :dark="row.TypeDestiny==3" x-small>
          {{row.TypeDestiny==1?'Europa':row.TypeDestiny==2?'USA':row.TypeDestiny==3?'Aéreo':'SN'}}
          </v-chip>
    </template>
     <template scope='props'>
      <v-col>
          <v-row justify="center">
              <v-col class="pb-0 pt-0" lg=2 cols=12> <s-text label="Lote" readonly :value=props.item.LorCode></s-text></v-col>
              <v-col lg=2 class="pb-0 pt-0">
                  <s-select-definition :def=1181 label='Destino'  v-model=props.item.TypeDestiny></s-select-definition>
              </v-col>
               <v-col cols=12 lg=2 class="pb-0 pt-0">
                  <s-text decimal label="Jabas Calibrado" autofocus v-model=props.item.TrzJabas />
              </v-col>
          </v-row>
          <v-row justify="center">
             
               <v-col cols=12 lg=2 class="pb-0 pt-1">
                  <s-text decimal label="Peso Calibrado(KG)" v-model=props.item.TrzCalibratedWeight />
              </v-col>
               <v-col cols=12 lg=2 class="pb-0 pt-1">
                  <s-text decimal label="Descarte Albarán(KG)" v-model=props.item.TrzDiscardAlbaran />
              </v-col>
                <v-col cols=12 lg=2  class="pt-1">
                  <s-text decimal label="Descarte Total" v-model=props.item.TrzDiscardTotal />
              </v-col>
          </v-row>
          <v-row justify="center">
              <v-col lg=1 class="pt-0 pb-0" cols=12  > <s-text label="." readonly value="06" /><v-divider /></v-col>
              <v-col lg=1 class="pt-0 pb-0" cols=12><s-text label="Jabas" v-model=props.item.TrzQuaNumberPrintSix /> </v-col>
              <v-col lg=1 class="pt-0 pb-0" cols=12><s-text label="Cajas" v-model=props.item.TrzQuaJabasSix /></v-col>
              <v-col lg=1 class="pt-0 pb-0" cols=12><s-text label="Impr" v-model=props.item.TrzQuaBoxesSix /> </v-col>
          </v-row>  
            <v-row justify="center">
              <v-col lg=1 class="pt-0 pb-0" cols=12  > <s-text label="." readonly value="07" /><v-divider /> </v-col>
              <v-col lg=1 class="pt-0 pb-0" cols=12><s-text label="Jabas" v-model=props.item.TrzQuaNumberPrintSeven /> </v-col>
              <v-col lg=1 class="pt-0 pb-0" cols=12><s-text label="Cajas" v-model=props.item.TrzQuaJabasSeven /></v-col>
              <v-col lg=1 class="pt-0 pb-0" cols=12><s-text label="Impr" v-model=props.item.TrzQuaBoxesSeven /> </v-col>
          </v-row>  
             <v-row justify="center">
              <v-col lg=1 class="pt-0 pb-0" cols=12  > <s-text label="." readonly value="08"></s-text> <v-divider></v-divider> </v-col>
              <v-col lg=1 class="pt-0 pb-0" cols=12><s-text label="Jabas" v-model=props.item.TrzQuaNumberPrintEight /> </v-col>
              <v-col lg=1 class="pt-0 pb-0" cols=12><s-text label="Cajas" v-model=props.item.TrzQuaJabasEight /></v-col>
              <v-col lg=1 class="pt-0 pb-0" cols=12><s-text label="Impr" v-model=props.item.TrzQuaBoxesEight /> </v-col>
          </v-row>  
             <v-row justify="center">
              <v-col lg=1 class="pt-0 pb-0" cols=12  > <s-text label="." readonly value="09"></s-text> <v-divider></v-divider> </v-col>
              <v-col lg=1 class="pt-0 pb-0" cols=12><s-text label="Jabas" v-model=props.item.TrzQuaNumberPrintNine /> </v-col>
              <v-col lg=1 class="pt-0 pb-0" cols=12><s-text label="Cajas" v-model=props.item.TrzQuaJabasNine /></v-col>
              <v-col lg=1 class="pt-0 pb-0" cols=12><s-text label="Impr" v-model=props.item.TrzQuaBoxesNine /> </v-col>
          </v-row>  
             <v-row justify="center">
              <v-col lg=1 class="pt-0 pb-0" cols=12  > <s-text label="." readonly value="10" /><v-divider /></v-col>
              <v-col lg=1 class="pt-0 pb-0" cols=12><s-text label="Jabas" v-model=props.item.TrzQuaNumberPrintTen /> </v-col>
              <v-col lg=1 class="pt-0 pb-0" cols=12><s-text label="Cajas" v-model=props.item.TrzQuaJabasTen /></v-col>
              <v-col lg=1 class="pt-0 pb-0" cols=12><s-text label="Impr" v-model=props.item.TrzQuaBoxesTen /> </v-col>
          </v-row>  
             <v-row justify="center">
              <v-col lg=1 class="pt-0 pb-0" cols=12  > <s-text label="." readonly value="12" /><v-divider /></v-col>
              <v-col lg=1 class="pt-0 pb-0" cols=12><s-text label="Jabas" v-model=props.item.TrzQuaNumberPrintTwelve /> </v-col>
              <v-col lg=1 class="pt-0 pb-0" cols=12><s-text label="Cajas" v-model=props.item.TrzQuaJabasTwelve /></v-col>
              <v-col lg=1 class="pt-0 pb-0" cols=12><s-text label="Impr" v-model=props.item.TrzQuaBoxesTwelve /> </v-col>
          </v-row>  
             <v-row justify="center">
              <v-col lg=1 class="pt-0 pb-0" cols=12  > <s-text label="." readonly value="14" /><v-divider /></v-col>
              <v-col lg=1 class="pt-0 pb-0" cols=12><s-text label="Jabas" v-model=props.item.TrzQuaNumberPrintFourteen /> </v-col>
              <v-col lg=1 class="pt-0 pb-0" cols=12><s-text label="Cajas" v-model=props.item.TrzQuaJabasFourteen /></v-col>
              <v-col lg=1 class="pt-0 pb-0" cols=12><s-text label="Impr" v-model=props.item.TrzQuaBoxesFourteen /> </v-col>
          </v-row>  
            
      </v-col>
    </template>
</s-crud>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import _sProduction from '@/services/Production/LotReceptionService'
export default {
     components: {
    },
    data() {
        return {
            headersQuality:[
                {text:'Calidad',value:'QalDescription'},
                {text:'Jabas',value:'QalJabas'},
                {text:'Cajas',value:'QalBoxes'},
                {text:'Impresiones',value:'QalNumberPrint'},
                {text:'Saldo',value:'QalBalance'},
                ],
                itemsQuality:[
                    {TypeQuality:'six',QalDescription:'06'},
                    {TypeQuality:'seven',QalDescription:'07'},
                    {TypeQuality:'eight',QalDescription:'08'},
                    {TypeQuality:'nine',QalDescription:'09'},
                    {TypeQuality:'ten',QalDescription:'10'},
                    {TypeQuality:'twelve',QalDescription:'12'},
                    {TypeQuality:'fourteen',QalDescription:'14'},
                ],
            showDialog: false,
            filter: {
               LorBeginDate: null,
               LorEndDate: null
            },
            filterAssig: {},
            entity: {},
            config: {
                model: {
                    LorCode: 'ID',
                    RecDateTime: 'datetime',
                    LorWeightLot:'decimal',
                    LorQuantityBox:'decimal',LorStatus:'',TypeDestiny:''
                },
                headers: [

                     {text:'Estado',value:'LorStatus'},
                     
                 {text:'Lote',value:'LorCode'},
                  {text:'Destino',value:'TypeDestiny'},
                    {text: 'Fecha',value: 'RecDateTime'},
                    { text: 'Variedad', value: 'VarDescription'},  
                    { text: 'Peso',value: 'LorWeightLot' },
                    { text: 'N° Jabas', value: 'LorQuantityBox'},
                ],
                service: _sProduction
            },
            items: [],
        };
    },
    methods: {      
        save(item) {
            if(item.TrzID==null) item.UsrCreateID=this.$fun.getUserID();
    
         if(this.validationForm(item)) item.save();
        },
        validationForm(item) {
           if(item.TrzJabas==0||item.TrzJabas==null) {this.$fun.alert('N° de Jabas debe ser mayor a 0','warning');return false;}
            return true; 
        },
    }
}
</script>
