import Service from "../Service";

const resource = "production/LotReception/";

export default {
  save(cmp, requestID) {
    return Service.post( "production/trazability/save", cmp, {
      params: { requestID: requestID },
    });
  },

  pagination(parameters, requestID) {
    return Service.post(resource + "pagination", parameters, {
      params: { requestID: requestID },
    });
  },
  excel(dtr, requestID) {
    return Service.post( "production/trazability/excel", dtr, {
        responseType: "blob",
        headers: {
            "Content-Type": "application/json",
        },
        params: { requestID: requestID },
    });
},
};
